import React, { useEffect, useState } from "react";

import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Box,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { green } from "@mui/material/colors";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_ENDPOINTS, BASE_URL } from "../../Utils/api";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(111, 135, 81, 1)",
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(111, 135, 81, 1)",
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: "rgba(111, 135, 81, 1)",
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: green[400],
          "&.Mui-checked": {
            color: "rgba(111, 135, 81, 1)",
          },
        },
      },
    },
  },
});
const BrandForm = () => {
  const [formData, setFormData] = useState({
    brandName: "",

    imgData: null,
  });
  const navigate = useNavigate();

  const handleSubmit = async e => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${BASE_URL}${API_ENDPOINTS.addBrand}`,

        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.statusCode === 0) {
        console.log("Add brand is unsuccessful");
        toast.error("Brand is not added");
      } else {
        setFormData({
          brandName: "",
          imgData: null,
        });
        console.log("Brand added successfully");
        toast.success("Brand added successfully", {
          onClose: () => navigate("/brands"),
        });
      }
    } catch (error) {
      toast.error("Brand is not added");
      console.error(error);
    }
  };
  const onChangeHandler = e => {
    setFormData(() => ({ ...formData, [e.target.name]: e.target.value }));
  };
  // const onFileChangeHandler = e => {
  //   setFormData(prevData => ({
  //     ...prevData,
  //     imgData: e.target.files[0].name,
  //   }));
  // };
  const onFileChangeHandler = e => {
    setFormData({ ...formData, imgData: e.target.files[0] });
  };
  return (
    <ThemeProvider theme={theme}>
      <Card sx={{ maxWidth: 800, margin: "auto", padding: "20px", mt: 5 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Create A New Brand
          </Typography>

          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <TextField
              fullWidth
              name="brandName"
              label="Brand Name"
              margin="normal"
              variant="outlined"
              onChange={onChangeHandler}
              value={formData.brandName}
              required
            />

            <Box sx={{ my: 2 }}>
              <Button
                variant="outlined"
                component="label"
                fullWidth
                sx={{ height: "56px" }}
                onChange={onFileChangeHandler}
              >
                Add image
                <input type="file" hidden />
              </Button>
              {formData.imgData && (
                <Typography variant="body2" sx={{ mt: 1 }}>
                  {formData.imgData.name}
                </Typography>
              )}
            </Box>

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                color="primary"
                style={{ backgroundColor: "rgba(111, 135, 81, 1)" }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Box>
          </Box>
          <ToastContainer />
        </CardContent>
      </Card>
    </ThemeProvider>
  );
};

export default BrandForm;
