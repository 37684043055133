import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Paper,
  TablePagination,
} from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import { API_ENDPOINTS, BASE_URL } from "../../Utils/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./new-category.css";
function NewCategories() {
  const [page, setPage] = useState(0); // Current page number
  const [rowsPerPage, setRowsPerPage] = useState(5); //
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}${API_ENDPOINTS.getCategories}`
        );
        if (response.data.statusCode === 0) {
          console.log("Fetching category is unsuccessfull");
        }
        setCategories(response.data.result);
      } catch (error) {
        console.error("Error fetching category data:", error);
      }
    };

    fetchData();
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const deleteCategory = async (id) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}${API_ENDPOINTS.deleteCategory}/${id}`
      );
      if (response.data.statusCode === 0) {
        console.log("Delete Category is unsuccessfull");
      }
      toast.success("Category deleted successfully")
      setCategories(prevData =>
        prevData.filter(product => product._id !== id)
      );

    } catch (error) {
      console.error("Error deleting Category:", error);
    }
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page number when rows per page changes
  };
  const pagenatedCategories = categories.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "95%",
        }}
      >
        <div
          style={{
            fontWeight: "600",
            fontSize: "1.5rem",
            margin: "auto 20px",
            marginLeft: "75px",
          }}
        >
          Categories
        </div>
        <NavLink to="/addCategory" style={{ textDecoration: "none" }}>
          <button className="btn-pay" type="button">
            New Category
          </button>
        </NavLink>
      </div>

      <TableContainer
        component={Paper}
        style={{
          width: "90%",
          margin: "auto",
          marginTop: "20px",
          backgroundColor: "#fff",
        }}
      >
        <Table aria-label="sales table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "600" }}>Category ID</TableCell>
              <TableCell style={{ fontWeight: "600" }}>Category Name</TableCell>

              <TableCell style={{ fontWeight: "600" }}>
                Brand Associated with this category
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pagenatedCategories.map(c => (
              <TableRow key={c._id}>
                <TableCell>{c._id}</TableCell>

                <TableCell>{c.categoryName}</TableCell>
                <TableCell>{c.brandName}</TableCell>
                <TableCell style={{ fontWeight: 'bold', color: 'red', cursor: 'pointer' }} onClick={() => deleteCategory(c._id)}>Delete</TableCell>
              </TableRow>

            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={categories.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        style={{ margin: "20px auto" }}
      />
      <ToastContainer />
    </>
  );
}

export default NewCategories;
