import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  TablePagination,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { API_ENDPOINTS, BASE_URL } from "../../Utils/api";

export const OrderTable = () => {
  const [sales, setSales] = useState([]);
  const [filteredSales, setFilteredSales] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0); // Current page number
  const [rowsPerPage, setRowsPerPage] = useState(5); //
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}${API_ENDPOINTS.getSales}`
        );
        setSales(response.data.resultObject);
      } catch (error) {
        console.error("Error fetching sales data:", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const filteredData = sales.filter(sale =>
      Object.values(sale).some(value =>
        value.toString().toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
    setFilteredSales(filteredData);
  }, [sales, searchQuery]);

  const handleSearchChange = event => {
    setSearchQuery(event.target.value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page number when rows per page changes
  };
  const filteredSalesPaginated = filteredSales.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <>
      <div
        style={{
          fontWeight: "600",
          fontSize: "1.5rem",
          margin: "20px",
          marginLeft: "70px",
        }}
      >
        Orders
      </div>
      <TextField
        label="Search"
        variant="outlined"
        style={{
          width: "40%",
          margin: "auto",
          marginTop: "20px",
          marginLeft: "70px",
        }}
        value={searchQuery}
        onChange={handleSearchChange}
      />
      <TableContainer
        component={Paper}
        style={{
          width: "90%",
          margin: "auto",
          marginTop: "20px",
          backgroundColor: "#fff",
        }}
      >
        <Table aria-label="sales table">
          <TableHead>
            <TableRow>
              <TableCell>Order No.</TableCell>
              <TableCell style={{ fontWeight: "600" }}>SALES ID</TableCell>
              <TableCell style={{ fontWeight: "600" }}>Order ID</TableCell>
              <TableCell style={{ fontWeight: "600" }}>PAYMENT ID</TableCell>
              <TableCell style={{ fontWeight: "600" }}>CUSTOMER ID</TableCell>

              <TableCell style={{ fontWeight: "600" }}>TOTAL PRICE </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredSalesPaginated.map(
              (
                {
                  _id,
                  razorpay_order_id,
                  razorpay_payment_id,
                  customerId,
                  price,
                },
                index
              ) => (
                <TableRow key={_id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <NavLink
                      style={{ textDecoration: "none", color: "olive " }}
                      to={`/order-details/${_id}`}
                    >
                      {_id}
                    </NavLink>
                  </TableCell>

                  <TableCell>{razorpay_order_id}</TableCell>
                  <TableCell>{razorpay_payment_id}</TableCell>
                  <TableCell>{customerId}</TableCell>
                  <TableCell>{price}</TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredSales.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        style={{ margin: "20px auto" }}
      />
    </>
  );
};
