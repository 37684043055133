import React, { useEffect, useState } from "react";
import OrderProcessing from "./OrderProcessing";
import OrderOverview from "./OrderOverview";
import Customer from "./Customer";
import { useParams } from "react-router-dom";
import { API_ENDPOINTS, BASE_URL } from "../../Utils/api";
import axios from "axios";

const OrderDetails = () => {
  const { _id } = useParams();
  const [sales, setSales] = useState({});
  const [product, setProduct] = useState([]);
  const [customer, setCustomer] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}${API_ENDPOINTS.getSalesByID}${_id}`
        );
        if (response.data.statusCode === 0) {
          console.log("Fetching sales is unsuccessfull");
        }
        setSales(response.data.resultObject);
      } catch (error) {
        console.error("Error fetching sales data:", error);
      }
    };

    fetchData();
  }, [_id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { customerId } = sales;
        const _id = customerId;
        const response = await axios.get(
          `${BASE_URL}${API_ENDPOINTS.getCustomerById}/${_id}`
        );

        if (response.data.statusCode === 0) {
          console.log("Fetching customer is unsuccessfull");
        }
        setCustomer(response.data.resultObject);
      } catch (error) {
        console.error("Error fetching customer data:", error);
      }
    };

    fetchData();
  }, [sales.customerId]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${BASE_URL}${API_ENDPOINTS.getProductsById}/${sales.productId[0]}`
  //       );
  //       if (response.data.statusCode === 0) {
  //         console.log("Fetching product is unsuccessfull");
  //       }
  //       setProduct(response.data.result);
  //     } catch (error) {
  //       console.error("Error fetching products data:", error);
  //     }
  //   };

  //   fetchData();
  // }, [sales.productId]);

  useEffect(() => {
    const fetchProductData = async () => {
      if (!sales.products || sales.products.length === 0) {
        console.warn("No products available in sales");
        return;
      }
  
      try {
        const productRequests = sales.products.map(({ productId }) =>
          axios.get(`${BASE_URL}${API_ENDPOINTS.getProductsById}/${productId}`)
        );
  
        const productResponses = await Promise.all(productRequests);
  
        const productsData = productResponses.map((response, index) => ({
          ...response.data.result, // Include fetched product data
          quantity: sales.products[index].quantity, // Add quantity from sales
        }));
  
        setProduct(productsData);
      } catch (error) {
        console.error("Error fetching products data:", error);
      }
    };
  
    fetchProductData();
  }, [sales.products]); // Trigger useEffect when sales.products changes
  
  

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <OrderOverview product={product} sales={sales} />
        <Customer customer={customer} />
      </div>

      {/* <OrderProcessing /> */}
    </>
  );
};

export default OrderDetails;
