import React, { useEffect, useState } from "react";
import TableUtils from "../../Utils/TableUtils";
import axios from "axios";
import { API_ENDPOINTS, BASE_URL } from "../../Utils/api";

const CustomerTable = () => {
  const [customer, setCustomer] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}${API_ENDPOINTS.getCustomer}`
        );
        if (response.data.statusCode === 0) {
          console.log("Fetching customer is unsuccessfull");
        }
        setCustomer(response.data.resultObject);
      } catch (error) {
        console.error("Error fetching sales data:", error);
      }
    };

    fetchData();
  }, []);
  const [filteredCustomer, setFilteredCustomer] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    const filteredData = customer.filter(sale =>
      Object.values(sale).some(value =>
        value.toString().toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
    setFilteredCustomer(filteredData);
  }, [customer, searchQuery]);
  const handleSearchChange = event => {
    setSearchQuery(event.target.value);
  };
  return (
    <div>
      <TableUtils
        customer={customer}
        handleSearchChange={handleSearchChange}
        filteredCustomer={filteredCustomer}
        searchQuery={searchQuery}
      />
    </div>
  );
};

export default CustomerTable;
