import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  FormControl,
  Box,
  createTheme,
  ThemeProvider,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import { green } from "@mui/material/colors";
import axios from "axios";
import { API_ENDPOINTS, BASE_URL } from "../../Utils/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(111, 135, 81, 1)",
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(111, 135, 81, 1)",
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: "rgba(111, 135, 81, 1)",
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: green[400],
          "&.Mui-checked": {
            color: "rgba(111, 135, 81, 1)",
          },
        },
      },
    },
  },
});

const ProductForm = () => {
  const [formData, setFormData] = useState({
    productName: "",
    brandId: "",
    categoryId: "",
    imgData: "",
    price: "",
    quantity: "",
  });

  const navigate = useNavigate();
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);

  // Fetch all brands on component mount
  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await axios.get(`${BASE_URL}${API_ENDPOINTS.getBrands}`);
        if (response.data.statusCode === 0) {
          console.log("Fetching brands is unsuccessful");
        } else {
          setBrands(response.data.result);
        }
      } catch (error) {
        console.error("Error fetching brands:", error);
      }
    };
    fetchBrands();
  }, []);

  // Fetch categories based on selected brand
  const fetchCategoriesByBrand = async (brandId) => {
    try {
      const response = await axios.get(`${BASE_URL}${API_ENDPOINTS.getCategoryByBrand}/${brandId}`);
      if (response.data.successCode === 0) {
        console.log("Fetching categories is unsuccessful");
      } else {
        setCategories(response.data.resultObject); // Access the resultObject
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  // Handle brand selection change
  const handleBrandChange = (e) => {
    const selectedBrandId = e.target.value;
    setFormData({ ...formData, brandId: selectedBrandId, categoryId: "" });
    fetchCategoriesByBrand(selectedBrandId); // Fetch categories for the selected brand
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    try {
      const response = await axios.post(
        `${BASE_URL}${API_ENDPOINTS.addProducts}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.statusCode === 0) {
        console.log("Add Product is unsuccessful");
        toast.error("Product is not added");
      } else {
        setFormData({
          productName: "",
          brandId: "",
          categoryId: "",
          imgData: null,
          price: "",
          quantity: "",
        });
        console.log("Product added successfully");
        toast.success("Product added successfully", {
          onClose: () => navigate("/products"),
        });
      }
    } catch (error) {
      toast.error("Product is not added");
      console.error(error);
    }
  };

  const onChangeHandler = (e) => {
    setFormData(() => ({ ...formData, [e.target.name]: e.target.value }));
  };

  const onFileChangeHandler = (e) => {
    setFormData({ ...formData, imgData: e.target.files[0] });
  };

  return (
    <ThemeProvider theme={theme}>
      <Card sx={{ maxWidth: 800, margin: "auto", padding: "20px", mt: 5 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Create A New Product
          </Typography>

          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <TextField
              fullWidth
              name="productName"
              label="Product Name"
              margin="normal"
              variant="outlined"
              onChange={onChangeHandler}
              value={formData.productName}
              required
            />

            {/* Brand Dropdown */}
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel id="brand-name-label">Brand Name</InputLabel>
              <Select
                labelId="brand-name-label"
                id="brand-name"
                name="brandId"
                value={formData.brandId}
                onChange={handleBrandChange} // Fetch categories on brand change
                label="Brand Name"
                required
              >
                {brands.map((brand) => (
                  <MenuItem key={brand._id} value={brand._id}>
                    {brand.brandName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Category Dropdown */}
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel id="category-name-label">Category Name</InputLabel>
              <Select
                labelId="category-label"
                id="category-name"
                name="categoryId"
                value={formData.categoryId}
                onChange={onChangeHandler}
                label="Category Name"
                required
              >
                {categories.map((category) => (
                  <MenuItem key={category._id} value={category._id}>
                    {category.categoryName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              fullWidth
              label="Price"
              name="price"
              margin="normal"
              variant="outlined"
              onChange={onChangeHandler}
              value={formData.price}
              required
            />

            <Box sx={{ my: 2 }}>
              <Button
                variant="outlined"
                component="label"
                fullWidth
                sx={{ height: "56px" }}
                onChange={onFileChangeHandler}
              >
                Add image
                <input type="file" hidden />
              </Button>
              {formData.imgData && (
                <Typography variant="body2" sx={{ mt: 1 }}>
                  {formData.imgData.name}
                </Typography>
              )}
            </Box>

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                color="primary"
                style={{ backgroundColor: "rgba(111, 135, 81, 1)" }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <ToastContainer />
    </ThemeProvider>
  );
};

export default ProductForm;
