import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Layout } from "./Pages/Layout/Layout";
import { OrderTable } from "./Pages/Order/OrderTable";
import OrderDetails from "./Pages/Order/OrderDetails";
import CustomerTable from "./Pages/Customer/CustomerTable";
import CouponTable from "./Pages/Coupon/CouponTable";
import NewCategories from "./Pages/Categories/NewCategories";
import { CategoryForm } from "./Pages/Categories/CategoryForm";
import NewBrand from "./Pages/Brands/NewBrand";
import BrandForm from "./Pages/Brands/BrandForm";
import Product from "./Pages/Product/Product";
import ProductForm from "./Pages/Product/ProductForm";
import CouponForm from "./Pages/Coupon/CouponForm";
import Login from "./Pages/Login/Login";
import { ToastContainer } from "react-toastify";
import PrivateRoute from "./PrivateRoute";
import { useState } from "react";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));






  return (
    <BrowserRouter>
      <Routes>


        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Layout />} >

          <Route path="/order" element={<OrderTable />} />
          <Route path="/customer" element={<CustomerTable />} />
          <Route path="/coupon" element={<CouponTable />} />
          <Route path="/order-details/:_id" element={<OrderDetails />} />
          <Route path="/categories" element={<NewCategories />} />
          <Route path="/addCategory" element={<CategoryForm />} />
          <Route path="/brands" element={<NewBrand />} />
          <Route path="/addBrand" element={<BrandForm />} />
          <Route path="/products" element={<Product />} />
          <Route path="/addProduct" element={<ProductForm />} />
          <Route path="/addCoupon" element={<CouponForm />} />

        </Route>


      </Routes>


    </BrowserRouter >
  );
}

export default App;
