import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { API_ENDPOINTS, BASE_URL } from "../../Utils/api";
import { useNavigate } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(111, 135, 81, 1)",
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(111, 135, 81, 1)",
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: "rgba(111, 135, 81, 1)",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(111, 135, 81, 1)",
          },
        },
      },
    },
  },
});

export const CategoryForm = () => {
  const [brands, setBrands] = useState([]);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    categoryName: "",
    brandIds: [], // Updated to store multiple IDs
    imgData: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}${API_ENDPOINTS.getBrands}`
        );
        if (response.data.statusCode === 0) {
          console.log("Fetching brands was unsuccessful");
        } else {
          setBrands(response.data.result);
        }
      } catch (error) {
        console.error("Error fetching brands:", error);
      }
    };

    fetchData();
  }, []);



 
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Prepare the form data
      const formDataToSend = new FormData();
      formDataToSend.append("categoryName", formData.categoryName);
      
      if (Array.isArray(formData.brandIds)) {
        formData.brandIds.forEach((brandId) => {
          formDataToSend.append("brandIds", brandId);
        });
      } else {
        formDataToSend.append("brandIds", formData.brandIds); // Single ID case
      }
  
      // Append image data if available
      if (formData.imgData) {
        formDataToSend.append("imgData", formData.imgData);
      }
  
      // Send the POST request
      const response = await axios.post(
        `${BASE_URL}${API_ENDPOINTS.addCategories}`, // Ensure template literals
        formDataToSend,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
  
      // Handle the response
      if (response.data?.successCode === 0) {
        toast.error(response.data?.message || "Category is not added");
      } else {
        toast.success(response.data?.message || "Category added successfully", {
          onClose: () => navigate("/categories"),
        });
      }
    } catch (error) {
      console.error("Error adding category:", error);
      toast.error("Category is not added");
    }
  };
  
  
  
  
  

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "brandIds" ? value : value, // Handle brandIds as an array
    }));
  };

  const onFileChangeHandler = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      imgData: e.target.files[0],
    }));
  };

  return (
    <ThemeProvider theme={theme}>
      <Card sx={{ maxWidth: 800, margin: "auto", padding: "20px", mt: 5 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Create A New Category
          </Typography>
          <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
            <TextField
              fullWidth
              name="categoryName"
              label="Category Name"
              margin="normal"
              variant="outlined"
              onChange={onChangeHandler}
              value={formData.categoryName}
              required
            />
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel id="brand-name-label">Brand Names</InputLabel>
              <Select
                labelId="brand-name-label"
                id="brand-name"
                name="brandIds"
                value={formData.brandIds} // Multi-select value
                onChange={onChangeHandler}
                label="Brand Names"
                multiple // Enable multiple selection
                required
              >
                {brands.map((brand) => (
                  <MenuItem key={brand._id} value={brand._id}>
                    {brand.brandName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box sx={{ my: 2 }}>
              <Button
                variant="outlined"
                component="label"
                fullWidth
                sx={{ height: "56px" }}
                onChange={onFileChangeHandler}
              >
                Add image
                <input type="file" hidden />
              </Button>
              {formData.imgData && (
                <Typography variant="body2" sx={{ mt: 1 }}>
                  {formData.imgData.name}
                </Typography>
              )}
            </Box>

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                color="primary"
                style={{ backgroundColor: "rgba(111, 135, 81, 1)" }}
                type="submit"
              >
                Submit
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <ToastContainer />
    </ThemeProvider>
  );
};
