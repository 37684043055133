import React, { useState } from "react";
import {
  FaTh,
  FaBars,
  FaUserAlt,
  FaRegChartBar,
  FaCommentAlt,
  FaShoppingBag,
  FaThList,
} from "react-icons/fa";
import { FaGift } from "react-icons/fa6";
import { NavLink } from "react-router-dom";
import "./sidebar.css";
import CategoryIcon from "@mui/icons-material/Category";
import StoreIcon from "@mui/icons-material/Store";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
const Sidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const menuItem = [
    {
      path: "/",
      name: "Dashboard",
      icon: <FaTh />,
    },
    {
      path: "/order",
      name: "Orders",
      icon: <FaShoppingBag />,
    },
    {
      path: "/customer",
      name: "Customers",
      icon: <FaUserAlt />,
    },
    {
      path: "/brands",
      name: "Brands",
      icon: <StoreIcon />,
    },
    {
      path: "/coupon",
      name: "Coupons",
      icon: <FaGift />,
    },
    {
      path: "/categories",
      name: "Category",
      icon: <CategoryIcon />,
    },
    {
      path: "/products",
      name: "Product",
      icon: <ShoppingBagIcon />,
    },

    // {
    //   path: "/analytics",
    //   name: "Analytics",
    //   icon: <FaRegChartBar />,
    // },
    // {
    //   path: "/comment",
    //   name: "Comment",
    //   icon: <FaCommentAlt />,
    // },

    // {
    //   path: "/product",
    //   name: "Product",
    //   icon: <FaShoppingBag />,
    // },
    // {
    //   path: "/productList",
    //   name: "Product List",
    //   icon: <FaThList />,
    // },
  ];
  return (
    <div className="container">
      <div style={{ width: isOpen ? "200px" : "50px" }} className="sidebar">
        <div className="top_section">
          {/* <h1 style={{ display: isOpen ? "block" : "none" }} className="logo">
            Logo
          </h1> */}
          <div
            style={{ marginLeft: isOpen ? "120px" : "0px" }}
            className="bars"
          >
            <FaBars onClick={toggle} />
          </div>
        </div>
        {menuItem.map((item, index) => (
          <NavLink
            to={item.path}
            key={index}
            className="link"
            activeclassName="active"
          >
            <div className="icon">{item.icon}</div>
            <div
              style={{ display: isOpen ? "block" : "none" }}
              className="link_text"
            >
              {item.name}
            </div>
          </NavLink>
        ))}
      </div>
      <main>{children}</main>
    </div>
  );
};

export default Sidebar;
