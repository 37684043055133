import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TextField,
  TableRow,
  Chip,
  Paper,
  TablePagination,
} from "@mui/material";
import { NavLink } from "react-router-dom";

const TableUtils = ({
  customer,
  searchQuery,
  handleSearchChange,
  filteredCustomer,
}) => {
  const [page, setPage] = useState(0); // Current page number
  const [rowsPerPage, setRowsPerPage] = useState(5); //
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page number when rows per page changes
  };
  const filteredCustomerPaginated = filteredCustomer.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  return (
    <>
      <div
        style={{
          fontWeight: "600",
          fontSize: "1.5rem",
          margin: "20px",
          marginLeft: "70px",
        }}
      >
        Customers
      </div>
      <TextField
        label="Search"
        variant="outlined"
        style={{
          width: "40%",
          margin: "auto",
          marginTop: "20px",
          marginLeft: "70px",
        }}
        value={searchQuery}
        onChange={handleSearchChange}
      />
      <TableContainer
        component={Paper}
        style={{
          width: "90%",
          margin: "auto",
          marginTop: "20px",
          backgroundColor: "#fff",
        }}
      >
        <Table aria-label="sales table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "600" }}>CUSTOMER ID</TableCell>
              <TableCell style={{ fontWeight: "600" }}>CUSTOMER NAME</TableCell>
              <TableCell style={{ fontWeight: "600" }}>EMAIL</TableCell>
              <TableCell style={{ fontWeight: "600" }}>PHONE NUMBER</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCustomerPaginated.map(c => (
              <TableRow key={c._id}>
                <TableCell>{c._id}</TableCell>
                <TableCell>{c.firstName + c.lastName}</TableCell>
                <TableCell>{c.email}</TableCell>
                <TableCell>{c.phoneNumber}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredCustomerPaginated.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        style={{ margin: "20px auto" }}
      />
    </>
  );
};

export default TableUtils;
