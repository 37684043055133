import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Paper,
  TablePagination,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { API_ENDPOINTS, BASE_URL } from "../../Utils/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const CouponTable = () => {
  const [page, setPage] = useState(0); // Current page number
  const [rowsPerPage, setRowsPerPage] = useState(5); //
  const [coupon, setCoupon] = useState([]);
  const deleteCoupon = async (id) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}${API_ENDPOINTS.deleteCoupon}/${id}`
      );
      if (response.data.statusCode === 0) {
        console.log("Delete Coupon is unsuccessfull");
      }
      toast.success("Coupon deleted successfully")
      setCoupon(prevData =>
        prevData.filter(product => product._id !== id)
      );
    } catch (error) {
      console.error("Error deleting Coupon:", error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}${API_ENDPOINTS.getCoupon}`
        );
        if (response.data.statusCode === 0) {
          console.log("Fetching customer is unsuccessfull");
        }
        setCoupon(response.data.resultObject);
      } catch (error) {
        console.error("Error fetching sales data:", error);
      }
    };

    fetchData();
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page number when rows per page changes
  };
  const pagenatedCoupon = coupon.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "95%",
        }}
      >
        <div
          style={{
            fontWeight: "600",
            fontSize: "1.5rem",
            margin: "auto 20px",
            marginLeft: "75px",
          }}
        >
          Coupons
        </div>
        <NavLink to="/addCoupon" style={{ textDecoration: "none" }}>
          <button className="btn-pay" type="button">
            New Coupon
          </button>
        </NavLink>
      </div>
      <TableContainer
        component={Paper}
        style={{
          width: "90%",
          margin: "auto",
          marginTop: "20px",
          backgroundColor: "#fff",
        }}
      >
        <Table aria-label="sales table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "600" }}>COUPON ID</TableCell>
              <TableCell style={{ fontWeight: "600" }}>COUPON NAME</TableCell>
              <TableCell style={{ fontWeight: "600" }}>COUPON CODE</TableCell>
              <TableCell style={{ fontWeight: "600" }}>
                COUPON DISCOUNT%
              </TableCell>
              <TableCell style={{ fontWeight: "600" }}>MAX DISCOUNT</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pagenatedCoupon.map(c => (
              <TableRow key={c._id}>
                <TableCell>{c._id}</TableCell>
                <TableCell>{c.couponName}</TableCell>
                <TableCell>{c.couponCode}</TableCell>
                <TableCell>{c.discountPercentage}</TableCell>
                <TableCell>{c.maxDiscountInRs}</TableCell>
                <TableCell style={{ fontWeight: 'bold', color: 'red', cursor: 'pointer' }} onClick={() => deleteCoupon(c._id)}>Delete</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={coupon.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        style={{ margin: "20px auto" }}
      />
      <ToastContainer />
    </>
  );
};

export default CouponTable;
