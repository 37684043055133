import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./login.css";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { API_ENDPOINTS, BASE_URL } from "../../Utils/api";
const Login = () => {
  const [formData, setFormData] = useState({ userId: "", password: "" });
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    userId: Yup.string().required("User ID is required"),
    password: Yup.string().required("Password is required"),
  });

  const onChangeHandler = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmitHandler = async e => {
    e.preventDefault();
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      const response = await axios.post(
        `${BASE_URL}${API_ENDPOINTS.login}`,
        formData
      );
      if (response.data.status) {
        localStorage.setItem("token", response.data.data); // Store the JWT token

        navigate("/order"); // Redirect to the admin dashboard
      }
    } catch (err) {
      setError(err.response?.data?.message || "Login failed");
      toast.error("Login is unsuccessfull");
    }
  };

  return (
    <div className="login-wrapper">
      <form className="login-form" onSubmit={onSubmitHandler}>
        <h2>Login</h2>
        <div className="form-group">
          <label htmlFor="userId">User ID</label>
          <input
            type="text"
            name="userId"
            id="userId"
            value={formData.userId}
            onChange={onChangeHandler}
            required
          />
          {error.userId && <div className="error">{error.userId}</div>}
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            name="password"
            id="password"
            value={formData.password}
            onChange={onChangeHandler}
            required
          />
          {error.password && <div className="error">{error.password}</div>}
        </div>
        {error.general && <div className="error">{error.general}</div>}
        <button type="submit" className="login-button">
          Login
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default Login;
