import React from "react";
import { Card, CardContent, Typography, Divider } from "@mui/material";

const Customer = ({ customer }) => {
  return (
    <Card sx={{ maxWidth: 345, padding: "10px", marginBottom: "30px" }}>
      <CardContent style={{ paddingBottom: 0 }}>
        <Typography variant="h6" component="div" style={{ fontWeight: "600" }}>
          CUSTOMER EMAIL
        </Typography>
        <Typography variant="body1" color="textSecondary" component="p">
          {customer.email}
        </Typography>
      </CardContent>
      <Divider style={{ margin: "20px 0" }} />
      <CardContent style={{ paddingTop: 0 }}>
        <Typography variant="h6" component="div" style={{ fontWeight: "600" }}>
          CUSTOMER ID
        </Typography>
        <Typography variant="body1" color="textSecondary" component="p">
          {customer._id}
        </Typography>
      </CardContent>
      <Divider style={{ margin: "20px 0" }} />
      <CardContent style={{ paddingTop: 0 }}>
        <Typography variant="h6" component="div" style={{ fontWeight: "600" }}>
          CONTACT INFORMATION
        </Typography>
        <Typography variant="body1" color="textSecondary" component="p">
          {customer.email}
        </Typography>
        <Typography variant="body1" color="textSecondary" component="p">
          {customer.phoneNumber}
        </Typography>
      </CardContent>
      <Divider style={{ margin: "20px 0" }} />
      <CardContent style={{ paddingTop: 0 }}>
        <Typography variant="h6" component="div" style={{ fontWeight: "600" }}>
          {customer.address}
        </Typography>
        <Typography variant="body1" color="textSecondary" component="p">
          {customer.firstName + customer.lastName}
        </Typography>
        <Typography variant="body1" color="textSecondary" component="p">
          {customer.flatHouseNo},{customer.famousLandmark}
        </Typography>
        <Typography variant="body1" color="textSecondary" component="p">
          {customer.state}
        </Typography>
        <Typography variant="body1" color="textSecondary" component="p">
          {customer.postalCode}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default Customer;
