import React from "react";
import { Grid, Card, CardContent, Typography, Divider } from "@mui/material";
import "./order-overview.css"; // Assuming this CSS file contains custom styles if needed

const OrderOverview = ({ product }) => {
  return (
    <div>
      {product.map((p, index) => (
        <Card
          key={index}
          sx={{
            padding: "10px",
            marginBottom: "30px",
            minHeight: "100px",
            height: "auto",
            width: "60%",
            margin: "15px auto", // Center the cards
          }}
        >
          <CardContent>
            <Grid container spacing={2} alignItems="center">
              {/* Product Image */}
              <Grid item xs={3} sm={2} md={2} lg={1}>
                <div style={{ position: "relative" }}>
                  <img
                    src={p.imgData || "/placeholder-image.jpg"} // Replace with actual placeholder
                    alt={p.productName || "Product"}
                    style={{ width: "100%", borderRadius: "4px" }}
                  />
                </div>
              </Grid>

              {/* Product Details */}
              <Grid item xs={9} sm={8} md={8} lg={9}>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  style={{ fontWeight: "600" }}
                >
                  {p.productName || "Unnamed product"}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {p.description || "No description available"}
                </Typography>
              </Grid>

              {/* Quantity and Price */}
              <Grid
                item
                xs={12}
                sm={2}
                md={2}
                lg={2}
                container
                direction="column"
                alignItems="flex-end"
              >
                <Typography variant="body2" color="textSecondary">
                  ₹{p.price} x {p.quantity}
                </Typography>
                <Typography
                  variant="h6"
                  component="div"
                  style={{ fontWeight: "600" }}
                >
                  ₹{p.price * p.quantity}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default OrderOverview;
