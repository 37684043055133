import React, { useState } from "react";
import { Avatar, Popover, Typography } from "@mui/material";
// import { SvgIcons } from "../../asserts/Icons/Svg";
// import logo from "../../asserts/Images/logo.jpg";
import NotificationsIcon from "@mui/icons-material/Notifications";

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenPopover = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  return (
    <div
      className="nav-header"
      style={{ backgroundColor: "rgba(111, 135, 81, 1)" }}
    >
      <div className="header-container">
        <div className="div-logo">
          {/* <img src={logo} alt="Logo" style={{ width: "95px" }} /> */}
        </div>
        <div className="div-right-nav">
          <div className="div-notification">
            {/* <SvgIcons name="NOTIFICATION" /> */}
            {/* <NotificationsIcon /> */}
          </div>
          <div className="div-person-aavatar">
            <Avatar
              src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
              variant="square"
              sx={{
                width: 48,
                height: 48,
                borderRadius: "10px",
                cursor: "pointer",
              }}
              onClick={handleOpenPopover}
            />
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <div className="popover-content">
                <div className="top-nav-bar">
                  <Avatar
                    src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                    variant="square"
                    sx={{ width: 48, height: 48, borderRadius: "50%" }}
                    onClick={handleOpenPopover}
                  />
                  <div className="nav-name-div">
                    <div className="navbarName">xyz nname</div>
                    <div className="navbarmail">xyz@email.com</div>
                  </div>
                </div>
                <div className="logout-button">
                  {/* <SvgIcons name="LOGOUT" /> */}
                  <div className="logout-text">
                    <Typography variant="body1" style={{ color: "black" }}>
                      Logout
                    </Typography>
                  </div>
                </div>
              </div>
            </Popover>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
