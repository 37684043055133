import React, { useEffect, useState } from "react";

import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Box,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { green } from "@mui/material/colors";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_ENDPOINTS, BASE_URL } from "../../Utils/api";
import { useNavigate } from "react-router-dom";
const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(111, 135, 81, 1)",
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(111, 135, 81, 1)",
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: "rgba(111, 135, 81, 1)",
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: green[400],
          "&.Mui-checked": {
            color: "rgba(111, 135, 81, 1)",
          },
        },
      },
    },
  },
});
const CouponForm = () => {
  const [formData, setFormData] = useState({
    couponName: "",
    couponCode: "",
    discountPercentage: 0,
    maxDiscountInRs: 0,
  });
  const navigate = useNavigate("/coupon");

  const handleSubmit = async e => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${BASE_URL}${API_ENDPOINTS.addCoupon}`,

        formData
      );
      if (response.data.statusCode === 0) {
        console.log("Add coupon is unsuccessful");
        toast.error("Coupon is not added");
      } else {
        setFormData({
          couponName: "",
          couponCode: "",
          discountPercentage: 0,
          maxDiscountInRs: 0,
        });
        console.log("Coupon added successfully");
        toast.success("Coupon added successfully", {
          onClose: () => navigate("/brands"),
        });
      }
    } catch (error) {
      toast.error("Coupon is not added");
      console.error(error);
    }
  };
  const onChangeHandler = e => {
    setFormData(() => ({ ...formData, [e.target.name]: e.target.value }));
  };
  // const onFileChangeHandler = e => {
  //   setFormData({ ...formData, imgData: e.target.files[0].name });
  // };
  return (
    <ThemeProvider theme={theme}>
      <Card sx={{ maxWidth: 800, margin: "auto", padding: "20px", mt: 5 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Create A New Coupon
          </Typography>

          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <TextField
              fullWidth
              name="couponName"
              label="Coupon Name"
              margin="normal"
              variant="outlined"
              onChange={onChangeHandler}
              value={formData.couponName}
              required
            />
            <TextField
              fullWidth
              label="Coupon Code"
              name="couponCode"
              margin="normal"
              variant="outlined"
              onChange={onChangeHandler}
              value={formData.couponCode}
              required
            />
            <TextField
              fullWidth
              label="Discount Percentage"
              name="discountPercentage"
              margin="normal"
              variant="outlined"
              onChange={onChangeHandler}
              value={formData.discountPercentage}
              required
            />
            <TextField
              fullWidth
              label="Maximum Discount"
              name="maxDiscountInRs"
              margin="normal"
              variant="outlined"
              onChange={onChangeHandler}
              value={formData.maxDiscountInRs}
              required
            />

            <Box sx={{ my: 2 }}>
              {/* <Button
                variant="outlined"
                component="label"
                fullWidth
                sx={{ height: "56px" }}
                onChange={onFileChangeHandler}
              >
                Add image
                <input type="file" hidden />
              </Button> */}
              {/* {formData.imgData && (
                <Typography variant="body2" sx={{ mt: 1 }}>
                  {formData.imgData.name}
                </Typography>
              )} */}
            </Box>

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                color="primary"
                style={{ backgroundColor: "rgba(111, 135, 81, 1)" }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <ToastContainer />
    </ThemeProvider>
  );
};

export default CouponForm;
