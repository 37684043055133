export const BASE_URL = 'https://noura-be.onrender.com'
// export const BASE_URL = 'http://localhost:4000'


export const API_ENDPOINTS = {
    getBrands: '/api/v1/brands/getBrands',
    bestSellers: '/api/v1/products/getBestSellersProduct',
    getCategories: '/api/v1/category/getCategory',
    addCategories: '/api/v1/category/addCategory',
    getProducts: '/api/v1/products/getProductByCategory',
    getProductsByBrands: '/api/v1/products/getProductByBrand',
    addCustomer: '/api/v1/customers/addCustomer',
    getSales: '/api/v1/sales/getAllSales',
    createOrder: '/api/v1/sales/createOrder',
    addSales: "/api/v1/sales/addSales",
    getCouponByCode: '/api/v1/coupon/getCouponByCode',
    getProductsById: '/api/v1/products/getProductById',
    getSalesByID: '/api/v1/sales/getSalesById/',
    getCustomer: '/api/v1/customers/getCustomers',
    getCoupon: '/api/v1/coupon/getAllCoupons',
    getCustomerById: '/api/v1/customers/getCustomerById',
    addBrand: "/api/v1/brands/addbrand",
    addProducts: "/api/v1/products/addProduct",
    getAllProducts: "/api/v1/products/getProducts",
    addCoupon: "/api/v1/coupon/createCoupon",
    login: "/api/v1/admin/login",
    deleteProduct: "/api/v1/products/deleteProduct",
    updateProduct: "/api/v1/products/updateProduct",
    deleteCategory: "/api/v1/category/deleteCategory",
    deleteBrand: "/api/v1/brands/deleteBrand",
    deleteCoupon: "/api/v1/coupon/removeCouponById",
    getCategoryByBrand: "/api/v1/category/getCategoryByBrand"


}