import React from "react";
import Sidebar from "./Sidebar";

import { OrderTable } from "../Order/OrderTable";
import { Outlet } from "react-router-dom";
import Header from "./Header";

export const Layout = () => {
  return (
    <>
      <Header />
      <Sidebar>
        <Outlet />
      </Sidebar>
    </>
  );
};
